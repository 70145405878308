import { Table, Form, Alert, Button, Modal, Row, Col } from "react-bootstrap";
import React from "react";

import {
  getColorBasedOnRowData,
  checkForApprovedQtyDiscrepancy,
} from "../../Inventory/common/utils";
import { Textarea } from "@mantine/core";
const userCity = sessionStorage.getItem("userCity") || "";
const userType = sessionStorage.getItem("userType") || "";

const ReceivedQtyTableCell = (props) => {
  const {
    isApprovedQtyFieldsShown,
    overallRouteSummaryDetailsHigh,
    setHighValueTableData,
    currentRoute,
    currentSelectedRoute,
    row,
    index,
  } = props;

  if (currentSelectedRoute === "All") {
    if (row.received_qty !== 0 && !row.received_qty) {
      return <td>Not Entered</td>;
    }
    return <td>{row.received_qty}</td>;
  }

  return (
    <td>
      <fieldset disabled={isApprovedQtyFieldsShown}>
        <Form.Control
          type="number"
          defaultValue={row.received_qty}
          onChange={(e) => {
            let val = e.target.value;
            let _routeSumDetails = {
              ...overallRouteSummaryDetailsHigh,
            };
            _routeSumDetails[currentRoute][index].received_qty = val;
            setHighValueTableData(_routeSumDetails);
          }}
        />
      </fieldset>
    </td>
  );
};


const TransitItemsTableHeader = (props) => {
  const isApprovedQtyFieldsShown = props.isApprovedQtyFieldsShown;

  return (
    <thead className="thead-light">
      <tr>
        <th>Item ID</th>
        <th>Item description || Type || Category</th>
        <th>Sub Category</th>
        <th>Brand</th>
        <th>Crate id</th>
        <th>Shipped Qty(Crates)</th>
        <th>Received Qty(Crates)</th>
        <th>Remarks</th>
        
      </tr>
    </thead>
  );
};

const TransitItemsTableBody = ({
  routeProductData,
  overallRouteSummaryDetailsHigh,
  setHighValueTableData,
  currentSelectedRoute,
  currentRoute,
  isApprovedQtyFieldsShown,
  isApprovedQtyFieldsEditable,
  showDiscrepancyItemsChecked,
}) => {
  let itemsListData = routeProductData;
 
  let rows = itemsListData.map((row, index) => {
    row.bgColor = getColorBasedOnRowData(
      row,
      isApprovedQtyFieldsEditable,
      isApprovedQtyFieldsShown
    );

    if (
      showDiscrepancyItemsChecked &&
      !checkForApprovedQtyDiscrepancy(
        row,
        isApprovedQtyFieldsEditable,
        isApprovedQtyFieldsShown,
        "transit"
      )
    ) {
      return null;
    }

    return (
      <tr
        key={index}
        style={{ backgroundColor: row.bgColor ? row.bgColor : "" }}
      >
        <td>{row.item_id}</td>
        <td>
          {" "}
          {row.item_disc} || {row.variety} || {row.category}
        </td>

        <td>{row.sub_category}</td>
        <td>{row.brand}</td>
        <td>{row.crate_id ? row.crate_id : "N/A"}</td>
        <td>{row.item_modified_qty}</td>
        <ReceivedQtyTableCell
          isApprovedQtyFieldsShown={isApprovedQtyFieldsShown}
          overallRouteSummaryDetailsHigh={overallRouteSummaryDetailsHigh}
          setHighValueTableData ={setHighValueTableData}
          currentRoute={currentRoute}
          currentSelectedRoute={currentSelectedRoute}
          row={row}
          index={index}
        />

        <td>
          <fieldset disabled={isApprovedQtyFieldsShown}>
            <Textarea
              placeholder="Enter received remarks"
              defaultValue={row.rec_remarks || ""}
              onChange={(e) => {
                let _routeSumDetails = {
                  ...overallRouteSummaryDetailsHigh,
                };
                _routeSumDetails[currentRoute][index].rec_remarks =
                  e.target.value;

                setHighValueTableData(_routeSumDetails)
              }}
            />
          </fieldset>
        </td>

      </tr>
    );
  });

  return (
    <>
      <tbody>{rows}</tbody>
    </>
  );
};

const TransitHighValueTable = ({
  routeProductData,
  overallRouteSummaryDetailsHigh,
  currentSelectedRoute,
  currentRoute,
  routeDropDownValues,
  setHighValueTableData,
  removeItemFromSummaryDetails,
  transitID,
  init,
  isApprovedQtyFieldsShown,
  isApprovedQtyFieldsEditable,
  showDiscrepancyItemsChecked,
}) => {
  return (
    <Table striped bordered hover style={{ marginBottom: "30px" }}>
      <TransitItemsTableHeader
        isApprovedQtyFieldsShown={isApprovedQtyFieldsShown}
      />
      <TransitItemsTableBody
        routeProductData={routeProductData}
        overallRouteSummaryDetailsHigh={overallRouteSummaryDetailsHigh}
        currentRoute={currentRoute}
        currentSelectedRoute={currentSelectedRoute}
        setHighValueTableData={setHighValueTableData}
        removeItemFromSummaryDetails={removeItemFromSummaryDetails}
        init={init}
        transitID={transitID}
        isApprovedQtyFieldsEditable={isApprovedQtyFieldsEditable}
        isApprovedQtyFieldsShown={isApprovedQtyFieldsShown}
        showDiscrepancyItemsChecked={showDiscrepancyItemsChecked}
      />
    </Table>
  );
};

export default TransitHighValueTable;
