import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import { Button, Select } from "@mantine/core";

import { InventoryMovementsTable } from "./InventoryMovementsTable";
import NavBar from "../Navigation bar/Navbar";
import moment from "moment";
import Backdrop from "../backdrop/backdrop";
import { toast } from "react-toastify";
import {
  fetchInventoryMovements,
  fetchInventoryMovementsExportAll,
  fetchPriceDetails,
} from "../../services/InventoryMovements.services";
import ExportToExcel from "../InventoryManagementExport/ExportToExcel";
import { warehouseNames } from "../../Configs/mysqlconfig";

const Header = styled.div`
  display: flex;
  height: 18vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 1rem;
`;

const PageTitle = styled.div`
  font-weight: bolder;
  font-size: 2rem;
  text-align: center;
`;

const DateTitle = styled.div`
  text-align: center;
`;
const DateSelector = styled(DatePicker)`
  margin-left: 1rem;
  margin-right: 1rem;
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PageContainer = styled.div`
  width: 100%;
`;

const TableContainer = styled.div`
  width: 100%;
  padding: 1rem;
  flex: 1;
`;

const WarehouseSelectContainer = styled.div`
  margin: 0 1rem;
  align-self: flex-end;
`;

const FetchDataButton = styled(Button)`
  margin-top: 1rem;
`;

const ButtonStyle = styled(Button)`
  margin-right: 1rem;
`;

const ExportBtnStyles = styled.div`
  margin-top: 1rem;
`;

// const warehouseList = [
//   { value: "All", label: "All" },
//   { value: "Goa", label: "Goa" },
//   { value: "Vashi", label: "Vashi" },
//   { value: "Mira Road", label: "Mira Road" },
//   { value: "Wadala", label: "Wadala" },
//   { value: "Bhiwandi", label: "Bhiwandi" },
// ];

const mapWarehouseList = (names) => {
  return names.map(name => ({
    value: name,
    label: name
  }));
};

const warehouseList = mapWarehouseList(warehouseNames);

function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows

  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      if (row.values[id] && row.values[id] !== "") options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        const value = e.target.value;
        setFilter(value === "All" ? undefined : value);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

const isNumber = (item) => {
  return isNaN(Number(item))?0:Number(item)
}

function getFooterSum(data, field) {
  if (!data.rows || data.rows.length === 0 || !field) {
    return 0;
  }

  let footerSum = data.rows.reduce((sum, row) => {
    return isNumber(row.values[field]) + sum;
  }, 0);
  return footerSum.toFixed(1);
}

const InventoryMovementsDetails = ({ history }) => {
  const [tableData, setTableData] = useState([]);
  let allWarehouseExportData = useState({});
  const [inventoryData, setInventoryData] = useState([]);
  const [exportTableDataByWarehouse, setExportTableDataByWarehouse] = useState(
    {}
  );
  const [dateRangeStart, setDateRangeStart] = useState(new Date());
  const [warehouse, setWarehouse] = useState(warehouseList[0].value);
  const [isLoading, setIsLoading] = useState(false);
  const [inventoryPriceDetails,setInventoryPriceDetails] = useState({});

  const greenColumnHeaderHighlight = "rgba(0,200,0,.6)";
  const redColumnHeaderHighlight = "rgba(200,0,0,.6)";

  const parentWarehouseColumns = React.useMemo(
    () => [
      {
        Header: "Item Id",
        Footer: "Total :",

        label: "Item Id",
        accessor: "item_id",
        value: "item_id",
        width: 90,
      },
      {
        Header: "Item Description || Brand",
        label: "Item Description || Brand",
        accessor: "desc_brand",
        value: "desc_brand",
        width: 180,
      },
      {
        Header: "Category",
        label: "Category",
        accessor: "category",
        value: "category",
        Filter: SelectColumnFilter,
        filter: "equals",
        width: 160,
      },
      {
        Header: "Sub Cat",
        label: "Sub Cat",
        accessor: "sub_category",
        value: "sub_category",
        Filter: SelectColumnFilter,
        filter: "equals",
        width: 160,
      },

      {
        Header: "Last day closing inventory ",
        label: "Last day closing inventory ",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "last_day_closing_inventory"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "last_day_closing_inventory",
        value: "last_day_closing_inventory",
      },
      {
        Header: "Inventory After Shipped",
        label: "Inventory After Shipped",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "inventory_after_shipped"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "inventory_after_shipped",
        value: "inventory_after_shipped",
      },
      {
        Header: "Inventory ",
        label: "Inventory ",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "resultant_inventory"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "resultant_inventory",
        value: "resultant_inventory",
      },
      {
        Header: "Inventory in KGs",
        label: "Inventory in KGs",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "resultant_inventory_weight"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "resultant_inventory_weight",
        value: "resultant_inventory_weight",
      },
      {
        Header: "Inventory Value",
        label: "Inventory Value",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "inventory_value"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "inventory_value",
        value: "inventory_value",
      },

      {
        Header: "GRN Qty",
        label: "GRN Qty",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "grn_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "grn_qty",
        value: "grn_qty",
        bgColor: greenColumnHeaderHighlight,
      },

      {
        Header: "Stock conversion",
        label: "Stock conversion",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "stock_transfer"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "stock_transfer",
        value: "stock_transfer",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Packaged Qty",
        label: "Packaged Qty",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "converted_stock"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "converted_stock",
        value: "converted_stock",
        bgColor: greenColumnHeaderHighlight,
      },
      {
        Header: "Shipped Qty",
        label: "Shipped Qty",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "shipped_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "shipped_qty",
        value: "shipped_qty",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Delivered Qty",
        label: "Delivered Qty",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "delivered_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "delivered_qty",
        value: "delivered_qty",
      },
      {
        Header: "Sample Qty",
        label: "Sample Qty",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "sample_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "sample_qty",
        value: "sample_qty",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Returned Qty",
        label: "Returned Qty",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "returned_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "returned_qty",
        value: "returned_qty",
        bgColor: greenColumnHeaderHighlight,
      },

      {
        Header: "Customer old returns",
        label: "Customer old returns",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "customer_returns"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "customer_returns",
        value: "customer_returns",
        bgColor: greenColumnHeaderHighlight,
      },
      {
        Header: "Shipped to depo",
        label: "Shipped to depo",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "shipped_to_child"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "shipped_to_child",
        value: "shipped_to_child",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Return from depo",
        label: "Return from depo",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "return_from_child"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "return_from_child",
        value: "return_from_child",
        bgColor: greenColumnHeaderHighlight,
      },

      {
        Header: "Dumped Qty",
        label: "Dumped Qty",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "dumped_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "dumped_qty",
        value: "dumped_qty",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Stock correction",
        label: "Stock correction",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "stock_correction"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "stock_correction",
        value: "stock_correction",
      },
      {
        Header: "Stock discrepancy",
        label: "Stock discrepancy",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "stock_discrepancy"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "stock_discrepancy",
        value: "stock_discrepancy",
      },
      {
        Header: "Stock movement",
        label: "Stock movement",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "stock_movement"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "stock_movement",
        value: "stock_movement",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "isActive",
        label: "isActive",
        id: "isActive",
        accessor: "active_status",
        value: "active_status",
        Filter: SelectColumnFilter,
        // filter: "equals",
        filter: (rows, id, filterValue) => {
          if (filterValue === "All" || !filterValue) {
            return rows; // No filtering
          }
          return rows.filter(row => row.values[id] === filterValue); // Apply specific filtering
        },
      },
    ],
    []
  );

  const childWarehouseColumns = React.useMemo(
    () => [
      {
        Header: "Item Id",
        label: "Item Id",
        Footer: "Total :",
        accessor: "item_id",
        value: "item_id",
        width: 90,
      },
      {
        Header: "Item Description || Brand",
        label: "Item Description || Brand",
        accessor: "desc_brand",
        value: "desc_brand",
        width: 180,
      },
      {
        Header: "Category",
        label: "Category",
        accessor: "category",
        value: "category",
        Filter: SelectColumnFilter,
        filter: "equals",
        width: 160,
      },
      {
        Header: "Sub category",
        label: "Sub category",
        accessor: "sub_category",
        value: "sub_category",
        Filter: SelectColumnFilter,
        filter: "equals",
        width: 160,
      },
      {
        Header: "Last day closing inventory ",
        label: "Last day closing inventory ",

        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "last_day_closing_inventory"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "last_day_closing_inventory",
        value: "last_day_closing_inventory",
      },
      {
        Header: "Inventory After Shipped",
        label: "Inventory After Shipped",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "inventory_after_shipped"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "inventory_after_shipped",
        value: "inventory_after_shipped",
      },

      {
        Header: "Inventory",
        label: "Inventory",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "resultant_inventory"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "resultant_inventory",
        value: "resultant_inventory",
      },

      {
        Header: "Inventory in KGs",
        label: "Inventory in KGs",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "resultant_inventory_weight"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "resultant_inventory_weight",
        value: "resultant_inventory_weight",
      },
      {
        Header: "Inventory Value",
        label: "Inventory Value",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "inventory_value"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "inventory_value",
        value: "inventory_value",
      },
      {
        Header: "Depo Inward",
        label: "Depo Inward",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "grn_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "grn_qty",
        value: "grn_qty",
        bgColor: greenColumnHeaderHighlight,
      },

      {
        Header: "Shipped Qty",
        label: "Shipped Qty",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "shipped_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "shipped_qty",
        value: "shipped_qty",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Delivered Qty",
        label: "Delivered Qty",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "delivered_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "delivered_qty",
        value: "delivered_qty",
      },

      {
        Header: "Returned Qty",
        label: "Returned Qty",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "returned_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "returned_qty",
        value: "returned_qty",
        bgColor: greenColumnHeaderHighlight,
      },

      {
        Header: "Customer old returns",
        label: "Customer old returns",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "customer_returns"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "customer_returns",
        value: "customer_returns",
        bgColor: greenColumnHeaderHighlight,
      },
      {
        Header: "Shipped to Bhiwandi",
        label: "Shipped to Bhiwandi",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "shipped_to_parent"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "shipped_to_parent",
        value: "shipped_to_parent",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Stock correction",
        label: "Stock correction",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "stock_correction"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "stock_correction",
        value: "stock_correction",
      },

      {
        Header: "Stock discrepancy",
        label: "Stock discrepancy",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "stock_discrepancy"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "stock_discrepancy",
        value: "stock_discrepancy",
      },
      ,
      {
        Header: "Stock movement",
        label: "Stock movement",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "stock_movement"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "stock_movement",
        value: "stock_movement",
        bgColor: greenColumnHeaderHighlight,
      },
      {
        Header: "isActive",
        label: "isActive",
        id: "isActive",
        accessor: "active_status",
        value: "active_status",
        Filter: SelectColumnFilter,
        // filter: "equals",
        filter: (rows, id, filterValue) => {
          if (filterValue === "All" || !filterValue) {
            return rows; // No filtering
          }
          return rows.filter(row => row.values[id] === filterValue); // Apply specific filtering
        },
      },
    ],
    []
  );

  const goaWarehouseColumns = React.useMemo(
    () => [
      {
        Header: "Item Id",
        label: "Item Id",
        Footer: "Total :",
        accessor: "item_id",
        value: "item_id",
        width: 90,
      },
      {
        Header: "Item Description || Brand",
        label: "Item Description || Brand",
        accessor: "desc_brand",
        value: "desc_brand",
        width: 180,
      },
      {
        Header: "Category",
        label: "Category",
        accessor: "category",
        value: "category",
        Filter: SelectColumnFilter,
        filter: "equals",
        width: 160,
      },
      {
        Header: "Sub category",
        label: "Sub category",
        accessor: "sub_category",
        value: "sub_category",
        Filter: SelectColumnFilter,
        filter: "equals",
        width: 160,
      },
      {
        Header: "Last day closing inventory ",
        label: "Last day closing inventory ",

        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "last_day_closing_inventory"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "last_day_closing_inventory",
        value: "last_day_closing_inventory",
      },
      {
        Header: "Inventory After Shipped",
        label: "Inventory After Shipped",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "inventory_after_shipped"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "inventory_after_shipped",
        value: "inventory_after_shipped",
      },

      {
        Header: "Inventory",
        label: "Inventory",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "resultant_inventory"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "resultant_inventory",
        value: "resultant_inventory",
      },

      {
        Header: "Inventory in KGs",
        label: "Inventory in KGs",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "resultant_inventory_weight"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "resultant_inventory_weight",
        value: "resultant_inventory_weight",
      },
      {
        Header: "Inventory Value",
        label: "Inventory Value",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "inventory_value"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "inventory_value",
        value: "inventory_value",
      },
      {
        Header: "Depo Inward",
        label: "Depo Inward",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "stock_movement"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "stock_movement",
        value: "stock_movement",
        bgColor: greenColumnHeaderHighlight,
      },

      {
        Header: "Shipped Qty",
        label: "Shipped Qty",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "shipped_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "shipped_qty",
        value: "shipped_qty",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Delivered Qty",
        label: "Delivered Qty",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "delivered_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "delivered_qty",
        value: "delivered_qty",
      },

      {
        Header: "Returned Qty",
        label: "Returned Qty",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "returned_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "returned_qty",
        value: "returned_qty",
        bgColor: greenColumnHeaderHighlight,
      },

      {
        Header: "Customer old returns",
        label: "Customer old returns",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "customer_returns"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "customer_returns",
        value: "customer_returns",
        bgColor: greenColumnHeaderHighlight,
      },
      {
        Header: "Stock correction",
        label: "Stock correction",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "stock_correction"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "stock_correction",
        value: "stock_correction",
      },

      {
        Header: "Stock discrepancy",
        label: "Stock discrepancy",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "stock_discrepancy"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "stock_discrepancy",
        value: "stock_discrepancy",
      },
      {
        Header: "isActive",
        label: "isActive",
        id: "isActive",
        accessor: "active_status",
        value: "active_status",
        Filter: SelectColumnFilter,
        // filter: "equals",
        filter: (rows, id, filterValue) => {
          if (filterValue === "All" || !filterValue) {
            return rows; // No filtering
          }
          return rows.filter(row => row.values[id] === filterValue); // Apply specific filtering
        },
      },
    ],
    []
  );

  const allWarehouseColumns = React.useMemo(
    () => [
      {
        Header: "Item Id",
        label: "Item Id",
        Footer: "Total :",
        accessor: "item_id",
        value: "item_id",
        width: 90,
      },
      {
        Header: "Item Description || Brand",
        label: "Item Description || Brand",
        accessor: "desc_brand",
        value: "desc_brand",
        width: 180,
      },
      {
        Header: "Category",
        label: "Category",
        accessor: "category",
        value: "category",
        Filter: SelectColumnFilter,
        filter: "equals",
        width: 160,
      },
      {
        Header: "Sub Cat",
        label: "Sub Cat",
        accessor: "sub_category",
        value: "sub_category",
        Filter: SelectColumnFilter,
        filter: "equals",
        width: 160,
      },

      {
        Header: "Last day closing inventory ",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "last_day_closing_inventory"),
            [data.rows]
          );

          return <>{total}</>;
        },
        label: "Last day closing inventory ",
        accessor: "last_day_closing_inventory",
        value: "last_day_closing_inventory",
      },
      {
        Header: "Inventory After Shipped",
        label: "Inventory After Shipped",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "inventory_after_shipped"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "inventory_after_shipped",
        value: "inventory_after_shipped",
      },
      {
        Header: "Inventory",
        label: "Inventory",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "resultant_inventory"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "resultant_inventory",
        value: "resultant_inventory",
      },
      {
        Header: "Inventory in KGs",
        label: "Inventory in KGs",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "resultant_inventory_weight"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "resultant_inventory_weight",
        value: "resultant_inventory_weight",
      },
      {
        Header: "Inventory Value",
        label: "Inventory Value",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "inventory_value"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "inventory_value",
        value: "inventory_value",
      },
      {
        Header: "GRN Qty",
        label: "GRN Qty",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "grn_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "grn_qty",
        value: "grn_qty",
        bgColor: greenColumnHeaderHighlight,
      },
      {
        Header: "Depo Inward",
        label: "Depo Inward",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "child_grn"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "child_grn",
        value: "child_grn",
        bgColor: greenColumnHeaderHighlight,
      },

      {
        Header: "Stock conversion",
        label: "Stock conversion",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "stock_transfer"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "stock_transfer",
        value: "stock_transfer",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Packaged Qty",
        label: "Packaged Qty",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "converted_stock"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "converted_stock",
        value: "converted_stock",
        bgColor: greenColumnHeaderHighlight,
      },
      {
        Header: "Shipped Qty",
        label: "Shipped Qty",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "shipped_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "shipped_qty",
        value: "shipped_qty",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Delivered Qty",
        label: "Delivered Qty",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "delivered_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "delivered_qty",
        value: "delivered_qty",
      },
      {
        Header: "Sample Qty",
        label: "Sample Qty",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "sample_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "sample_qty",
        value: "sample_qty",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Returned Qty",
        label: "Returned Qty",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "returned_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "returned_qty",
        value: "returned_qty",
        bgColor: greenColumnHeaderHighlight,
      },

      {
        Header: "Customer old returns",
        label: "Customer old returns",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "customer_returns"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "customer_returns",
        value: "customer_returns",
        bgColor: greenColumnHeaderHighlight,
      },
      {
        Header: "Shipped to Bhiwandi",
        label: "Shipped to Bhiwandi",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "shipped_to_parent"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "shipped_to_parent",
        value: "shipped_to_parent",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Shipped to depo",
        label: "Shipped to depo",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "shipped_to_child"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "shipped_to_child",
        value: "shipped_to_child",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Return from depo",
        label: "Return from depo",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "return_from_child"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "return_from_child",
        value: "return_from_child",
        bgColor: greenColumnHeaderHighlight,
      },

      {
        Header: "Dumped Qty",
        label: "Dumped Qty",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "dumped_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "dumped_qty",
        value: "dumped_qty",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Stock correction",
        label: "Stock correction",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "stock_correction"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "stock_correction",
        value: "stock_correction",
      },
      {
        Header: "Stock discrepancy",
        label: "Stock discrepancy",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "stock_discrepancy"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "stock_discrepancy",
        value: "stock_discrepancy",
      },
      {
        Header: "Stock movement",
        label: "Stock movement",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "stock_movement"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "stock_movement",
        value: "stock_movement",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Intransit transfer",
        label: "Intransit transfer",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "intransit_transfer"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "intransit_transfer",
        value: "intransit_transfer",
      },
      {
        Header: "Intransit return",
        label: "Intransit return",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "intransit_return"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "intransit_return",
        value: "intransit_return",
      },
      {
        Header: "isActive",
        label: "isActive",
        id: "isActive",
        accessor: "active_status",
        value: "active_status",
        Filter: SelectColumnFilter,
        // filter: "equals",
        filter: (rows, id, filterValue) => {
          if (filterValue === "All" || !filterValue) {
            return rows; // No filtering
          }
          return rows.filter(row => row.values[id] === filterValue); // Apply specific filtering
        },
      },
    ],
    []
  );

  async function getAllInventoryMovementsExport() {
    setIsLoading(true);
    Promise.all([
      fetchInventoryMovementsExportAll(
        moment(dateRangeStart).format("YYYY-MM-DD")
      ),
      fetchPriceDetails(),
    ])
      .then((result) => {
        console.log("fetched export data");
        const inventoryExportData = result[0].data;
        const itemPriceDetails = result[1].data;
        setInventoryPriceDetails(result[1].data);
        const inventoryByWarehouse = {};
        warehouseList.forEach(({ value }) => {
          inventoryByWarehouse[value] = [];
        });
        inventoryExportData.forEach((item) => {
          if (item.warehouse && item.warehouse != "") {
            item.desc_brand = `${item.item_disc || ""} -- ${item.brand || ""}`;
            const item_price =
            itemPriceDetails[item.item_id]?.landing_cost || "N/A";
            if (item_price !== "N/A") {
              item.inventory_value = Number(
                item.resultant_inventory_weight * item_price
              ).toFixed(2);
            }
            if (inventoryByWarehouse.hasOwnProperty(item.warehouse)) {
              console.log(item.warehouse);
              inventoryByWarehouse[item.warehouse].push(item);
            }            
          }
        });
        console.log("check export data", inventoryByWarehouse);
        setIsLoading(false);
        allWarehouseExportData = inventoryByWarehouse;
        setInventoryData(inventoryByWarehouse);
      })
      .catch((err) => {
        toast(`Error : ${err}`, {
          type: toast.TYPE.ERROR,
        });
        setIsLoading(false);
      });
  }

  function getInventoryMovementsTableData() {
        const inventoryMovementsData = inventoryData[warehouse];
        const itemPriceDetails =inventoryPriceDetails;
        inventoryMovementsData.forEach((item) => {
          const item_price = itemPriceDetails[item.item_id]?.landing_cost || "N/A";
          item.shipped_qty = parseFloat(item.shipped_qty || 0).toFixed(2);
          item.delivered_qty = parseFloat(item.delivered_qty || 0).toFixed(2);
          item.customer_returns = parseFloat(item.customer_returns || 0).toFixed(2);
          item.stock_correction = parseFloat(item.stock_correction || 0).toFixed(2);
          item.shipped_to_parent = parseFloat(item.shipped_to_parent || 0).toFixed(2);
          item.shipped_to_child = parseFloat(item.shipped_to_child || 0).toFixed(2);
          item.returned_qty = parseFloat(item.returned_qty || 0).toFixed(2);
          item.return_from_child = parseFloat(item.return_from_child || 0).toFixed(2);
          item.dumped_qty = parseFloat(item.dumped_qty || 0).toFixed(2);
          item.grn_qty = parseFloat(item.grn_qty || 0).toFixed(2);
          item.child_grn = parseFloat(item.child_grn || 0).toFixed(2);
          item.stock_take = parseFloat(item.stock_take || 0).toFixed(2);
          item.bag_weight_loss = parseFloat(item.bag_weight_loss || 0).toFixed(2);
          item.stock_transfer = parseFloat(item.stock_transfer || 0).toFixed(2);
          item.converted_stock = parseFloat(item.converted_stock || 0).toFixed(2);
          item.wastage = parseFloat(item.wastage || 0).toFixed(2);
          item.sample_qty = parseFloat(item.sample_qty || 0).toFixed(2);
          item.inventory_value = "N/A";

          item.resultant_inventory =parseFloat(item.resultant_inventory || 0).toFixed(2); 
          item.last_day_closing_inventory =parseFloat(item.last_day_closing_inventory || 0).toFixed(2);
          item.inventory_after_shipped = parseFloat(item.inventory_after_shipped || 0).toFixed(2);
          item.resultant_inventory = parseFloat(item.resultant_inventory).toFixed(2);
          item.resultant_inventory_weight = parseFloat(item.resultant_inventory_weight).toFixed(2);

          if (item_price !== "N/A") {
            item.inventory_value = parseFloat(
              item.resultant_inventory_weight * item_price
            ).toFixed(2);
          }

          item.desc_brand = `${item.item_disc || ""} -- ${item.brand || ""}`;
          item.intransit_transfer = parseFloat(item.intransit_transfer || 0).toFixed(2);
          item.intransit_return = parseFloat(item.intransit_return || 0).toFixed(2);
        });
        setTableData(inventoryMovementsData);
  }

  useEffect(() => {
    if (warehouse && inventoryData && inventoryData[warehouse] && inventoryData[warehouse].length>0) {
      getInventoryMovementsTableData();
    }
  }, [warehouse,inventoryData]);

  useEffect(() => {
    if (dateRangeStart) getAllInventoryMovementsExport();
  }, [dateRangeStart]);

  return (
    <>
      <NavBar />
      <br />
      <br />
      <br />
      <PageContainer>
        <Header>
          <PageTitle> Inventory</PageTitle>
          <DateContainer>
            <DateTitle>Start Date</DateTitle>
            <DateSelector
              selected={dateRangeStart}
              onChange={setDateRangeStart}
              maxDate={new Date()}
              // dateFormat="dd/MM/yyyy"
            />
          </DateContainer>
          <WarehouseSelectContainer>
            <Select
              label="Warehouse"
              placeholder="Select"
              value={warehouse}
              data={warehouseList}
              onChange={setWarehouse}
            />
          </WarehouseSelectContainer>
          <ButtonStyle
            size="sm"
            color="blue"
            onClick={() => {
              history.push("/inventory-reports");
            }}
          >
            Reports
          </ButtonStyle>{" "}
          <ExportBtnStyles>
            <ExportToExcel
              sheet1={tableData}
              multiSheets={
                inventoryData && warehouse == "All" ? inventoryData : null
              }
              filename={"Inventory_movements"}
              sheet1Headers={
                warehouse === "Bhiwandi"
                  ? parentWarehouseColumns
                  : warehouse === "All"
                  ? allWarehouseColumns
                  : warehouse === "Goa"
                  ? goaWarehouseColumns
                  : childWarehouseColumns
              }
              sheet1Name={warehouse}
           />
         </ExportBtnStyles>
        </Header>

        <TableContainer>
          <InventoryMovementsTable
            tableData={tableData}
            parentWarehouseColumns={parentWarehouseColumns}
            childWarehouseColumns={childWarehouseColumns}
            goaWarehouseColumns={goaWarehouseColumns}
            allWarehouseColumns={allWarehouseColumns}
            warehouse={warehouse}
            history={history}
          />
        </TableContainer>
      </PageContainer>
      {isLoading ? <Backdrop parentLoadStatus={isLoading} /> : null}
    </>
  );
};

export default InventoryMovementsDetails;
