import React, { useMemo } from "react";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useBlockLayout,
} from "react-table";
import styled from "styled-components";
import { FixedSizeList } from "react-window";

const TableStyles = styled.div`
  font-size: 0.7rem;
  border-spacing: 0;
  border: 1px solid black;
  display: inline-block;

  .thead {
    box-shadow: 0px 2px 10px black;
  }

  .tr {
    :last-child {
      .td {
        border-bottom: 0;
      }
    }
  }

  .th,
  .td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid black;
    border-right: 1px solid black;

    :last-child {
      border-right: 0;
    }
  }

  .tfoot {
    box-shadow: 0px -5px 10px black;
    .tr:first-child {
      .td {
        border-top: 2px solid red;
      }
    }
    font-weight: bolder;

    .td {
      color: #0010ff;
    }
  }
`;

const TheadStyles = styled.div`
  font-weight: 700;
  background-color: ${(props) => props.$bgColor};
`;

const SearchContainer = styled.div`
  margin-bottom: 20px;
  width: 31%;
`;

const Search = styled.input`
  width: 100%;
`;

const scrollbarWidth = () => {
  const scrollDiv = document.createElement("div");
  scrollDiv.setAttribute(
    "style",
    "width: 100px; height: 100px; overflow: scroll; position:absolute; top:-9999px;"
  );
  document.body.appendChild(scrollDiv);
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);
  return scrollbarWidth;
};

export default scrollbarWidth;

const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <SearchContainer>
      <Search
        value={filter || ""}
        onChange={(e) => setFilter(e.target.value)}
        placeholder="Search"
      />{" "}
    </SearchContainer>
  );
};

export const InventoryMovementsTable = ({
  tableData: data,
  warehouse,
  allWarehouseColumns,
  childWarehouseColumns,
  parentWarehouseColumns,
  goaWarehouseColumns,
  history,
}) => {
  let columns = useMemo(() => {
    if (warehouse === "Bhiwandi") {
      return parentWarehouseColumns;
    } else if (warehouse === "All") {
      return allWarehouseColumns;
    } else if (warehouse === "Goa") {
      return goaWarehouseColumns;
    } else {
      return childWarehouseColumns;
    }
  }, [warehouse]);

  //!Due to an issue with the react-table library, Select filter does not work without this dummmy function.
  //!Also this is being passed to useTable
  const defaultColumn = React.useMemo(
    () => ({
      Filter: () => null,
      width: 80,
    }),
    []
  );

  const scrollBarSize = React.useMemo(() => scrollbarWidth(), []);

  // Use the state and functions returned from useTable to build your UI

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    preGlobalFilteredRows,
    totalColumnsWidth,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        filters: [{ id: "isActive", value: "All" }],
      },
      autoResetFilters: true,
      autoResetHiddenColumns: true,
      autoResetPage: true,
    },
    useFilters,
    useGlobalFilter,
    useBlockLayout
  );

  const { globalFilter } = state;

  const getItemInventoryDetailsUrl = (cell, warehouse) => {
    return `/item-inventory-movements?warehouse=${warehouse}&item_id=${cell.value}`;
  };

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);

      return (
        // Apply the row props
        <div {...row.getRowProps({ style })} className="tr">
          {/* // Loop over  the rows cells */}
          {row.cells.map((cell) => {
            if (cell.column.id !== "item_id") {
              return (
                <div {...cell.getCellProps()} className="td">
                  {cell.render("Cell")}{" "}
                </div>
              );
            } else {
              return (
                <div {...cell.getCellProps()} className="td">
                  <a
                    href={getItemInventoryDetailsUrl(cell, warehouse)}
                    target="_blank"
                  >
                    {" "}
                    {cell.render("Cell")}
                  </a>{" "}
                </div>
              );
            }
          })}
        </div>
      );

      // return (
      //   <div
      //     {...row.getRowProps({
      //       style,
      //     })}
      //     className="tr"
      //   >
      //     {row.cells.map((cell) => {
      //       return (
      //         <div {...cell.getCellProps()} className="td">
      //           {cell.render("Cell")}
      //         </div>
      //       );
      //     })}
      //   </div>
      // );
    },
    [prepareRow, rows]
  );

  // Render the UI for your table
  return (
    <>
      <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />

      {/* //Apply the table props */}
      <TableStyles {...getTableProps()}>
        <div className="thead">
          {/* // Loop over the header rows */}
          {headerGroups.map((headerGroup) => (
            // Apply the header row props
            <div {...headerGroup.getHeaderGroupProps()} className="tr">
              {/* // Loop over the headers in each row */}
              {headerGroup.headers.map((column) => (
                // Apply the header cell props

                <TheadStyles
                  $bgColor={column.bgColor}
                  {...column.getHeaderProps()}
                  // style={{ backgroundColor: column.bgColor || "white" }}
                  className="th"
                >
                  {/* // Render the header */}

                  {column.render("Header")}

                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </TheadStyles>
              ))}
            </div>
          ))}
        </div>
        {/*  Apply the table body props  */}
        <div {...getTableBodyProps()}>
          <FixedSizeList
            height={500}
            itemCount={rows.length}
            itemSize={95}
            width={totalColumnsWidth + scrollBarSize}
          >
            {RenderRow}
          </FixedSizeList>
        </div>
        <div className="tfoot">
          {footerGroups.map((group) => (
            <div {...group.getFooterGroupProps()} className="tr">
              {group.headers.map((column) => (
                <div {...column.getFooterProps()} className="td">
                  {column.render("Footer")}
                </div>
              ))}
            </div>
          ))}
        </div>
      </TableStyles>
    </>
  );
};
