import React from "react";
import ReactExport from "react-data-export";
import { Button } from "react-bootstrap";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const userType = sessionStorage.getItem("userType") || "";

class ExportToExcel extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    this.sheet1 = this.props.sheet1;
    this.sheet1Headers = this.props.sheet1Headers;
    this.bagItemsById = this.props.bagItemsById;
    this.sheet2 = this.props.sheet2;
    this.sheet2Headers = this.props.sheet2Headers;
    this.sheet3 = this.props.sheet3;
    this.sheet3Headers = this.props.sheet3Headers;
    this.filename = this.props.filename;
    this.sheet1Name = this.props.sheet1Name;
    this.sheet2Name = this.props.sheet2Name;
    this.sheet3Name = this.props.sheet3Name;

    // console.log("check data", this.sheet2);
    // console.log("check headers", this.sheetHeaders);
    return (
      <>
        {/* {userType === "Admin" ? ( */}
        <ExcelFile
          element={
            <Button variant="warning">
              {" "}
              {this.props.buttonText ? this.props.buttonText : "Export"}
            </Button>
          }
          filename={this.filename}
        >
          <ExcelSheet data={this.sheet1} name={this.sheet1Name}>
            {Object.keys(this.sheet1Headers).map((key, index) => {
              return (
                <ExcelColumn
                  key={index}
                  label={this.sheet1Headers[key].label}
                  value={this.sheet1Headers[key].value}
                />
              );
            })}
          </ExcelSheet>
          <ExcelSheet data={this.sheet2} name={this.sheet2Name}>
            {Object.keys(this.sheet2Headers).map((key, index) => {
              return (
                <ExcelColumn
                  key={index}
                  label={this.sheet2Headers[key].label}
                  value={this.sheet2Headers[key].value}
                />
              );
            })}
          </ExcelSheet>
          <ExcelSheet data={this.sheet3} name={this.sheet3Name}>
            {Object.keys(this.sheet3Headers).map((key, index) => {
              return (
                <ExcelColumn
                  key={index}
                  label={this.sheet3Headers[key].label}
                  value={this.sheet3Headers[key].value}
                />
              );
            })}
          </ExcelSheet>

        </ExcelFile>
        {/* ) : (
          ""
        )} */}
      </>
    );
  }
}

export default ExportToExcel;
