import React from "react";
import ReactExport from "react-data-export";
import { Button } from "react-bootstrap";
import  {validateIsInventoryUser} from "../../UserCredentials";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const userType = sessionStorage.getItem("userType") || "";
// const user = JSON.parse(sessionStorage.getItem("user"))[0].email;

class ExportToExcel extends React.Component {
  constructor(props) {
    super(props);
  }

  formatDataBeforeExport(looseItemsData, bagItemsById = {}) {
    if (!looseItemsData) {
      return [];
    }
    const dataToReturn = [];

    Object.keys(bagItemsById).forEach((key, index) => {
      const looseBagItems = bagItemsById[key];
      looseBagItems.forEach((item, index) => {
        const looseItem = { ...item };

        if (item.receivedToggleVal === "Y") {
          looseItem.received = "Yes";
        } else if (item.receivedToggleVal === "N") {
          looseItem.received = "No";
        }

        if (item.receivedToggleVal === "N" && item.approvedToggleVal === "Y") {
          looseItem.approved = "Yes";
        } else if (
          item.receivedToggleVal === "N" &&
          item.approvedToggleVal === "N"
        ) {
          looseItem.approved = "No";
        }
        dataToReturn.push(looseItem);
      });
    });

    return dataToReturn;
  }

  render() {
    this.sheet1 = this.props.sheet1;
    this.sheet1Headers = this.props.sheet1Headers;
    this.bagItemsById = this.props.bagItemsById;
    this.sheet2 = this.formatDataBeforeExport(
      this.props.sheet2,
      this.bagItemsById
    );
    this.sheet2Headers = this.props.sheet2Headers;
    this.sheet3=this.props.sheet3;
    this.sheet3Headers = this.props.sheet3Headers;
    this.filename = this.props.filename;
    this.sheet1Name = this.props.sheet1Name;
    this.sheet2Name = this.props.sheet2Name;
    this.sheet3Name = this.props.sheet3Name;

    return (
      <>
          <ExcelFile
            element={
              <Button variant="warning">
                {this.props.buttonText ? this.props.buttonText : "Export"}
              </Button>
            }
            filename={this.filename}
          >
            <ExcelSheet data={this.sheet1} name={this.sheet1Name}>
              {Object.keys(this.sheet1Headers).map((key, index) => {
                return (
                  <ExcelColumn
                    key={index}
                    label={this.sheet1Headers[key].label}
                    value={this.sheet1Headers[key].value}
                  />
                );
              })}
            </ExcelSheet>
            <ExcelSheet data={this.sheet2} name={this.sheet2Name}>
              {Object.keys(this.sheet2Headers).map((key, index) => {
                return (
                  <ExcelColumn
                    key={index}
                    label={this.sheet2Headers[key].label}
                    value={this.sheet2Headers[key].value}
                  />
                );
              })}
            </ExcelSheet>
            <ExcelSheet data={this.sheet3} name={this.sheet3Name}>
              {Object.keys(this.sheet3Headers).map((key, index) => {
                return (
                  <ExcelColumn
                    key={index}
                    label={this.sheet3Headers[key].label}
                    value={this.sheet3Headers[key].value}
                  />
                );
              })}
            </ExcelSheet>

          </ExcelFile>
      </>
    );
  }
}

export default ExportToExcel;
