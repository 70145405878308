import React, { useState, useEffect, useRef } from "react";
import { Table, Form, Alert, Button, Modal, Row, Col } from "react-bootstrap";

import { service_url, token } from "../../Configs/mysqlconfig";
import moment from "moment";
import { toast } from "react-toastify";
import Select from "react-select";
import { TransitReturnTable } from "./Tables/TransitReturnItemsTable";
import { TransitReturnHighValueTable } from "./Tables/TransitHighValueItemsTable";
import { TransitReturnLooseTable } from "./Tables/TransitLooseItemsTable";
import { Button as MantineButton } from "@mantine/core";
import Backdrop from "../backdrop/backdrop";
import ExportToExcel from "../InventoryManagementExport/TransitStockReturnExport/ExportToExcel";
import {
  redHighlightColor,
  greenHighlightColor,
  orangeHighlightColor,
  sortByTypeCategoryItemId,
} from "../Inventory/common/constants";
import ShowDiscrepancyCheckbox from "../Inventory/common/ShowDiscrepancyCheckbox";
import TransitRouteItemsModal from "../TransitStockTransfer/TransitRouteItemsModal";

const GLOBAL_PARENT_WAREHOUSE = "Bhiwandi";
const userCity = sessionStorage.getItem("userCity") || "";
const userType = sessionStorage.getItem("userType") || "";

function formatDate(date) {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

function TransitReturnItemsModal({
  transitID,
  warehouse,
  returnDate,
  type,
  productMasterDataRawFresh,
  ...props
}) {
  const [routeDropDownValues, setRouteDropDownValues] = useState([]);
  const [currentSelectedRoute, setCurrentSelectedRoute] = useState("");
  const [overallReturnSummaryDetails, setOverallReturnSummaryDetails] =
    useState([]);
    const [overallHighValueReturnSummaryDetails, setOverallHighValueReturnSummaryDetails] =
    useState([]);

  const [overallLooseSummaryDetails, setOverallLooseSummaryDetails] = useState(
    []
  );

  const [selectedItemId, setSelectedItemId] = useState("");
  const [backDrop, setBackDrop] = useState(false);
  const [isApprovedQtyFieldsShown, setIsApprovedQtyFieldsShown] =
    useState(false);
  const [isApprovedQtyFieldsEditable, setIsApprovedQtyFieldsEditable] =
    useState(false);
    const [isApprovedQtyFieldsShownHighValue, setIsApprovedQtyFieldsShownHighValue] =
    useState(false);
  const [isApprovedQtyFieldsEditableHighValue, setIsApprovedQtyFieldsEditableHighValue] =
    useState(false);
  const [showDiscrepancyItemsChecked, setShowDiscrepancyItemsChecked] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const addTransitItemDropdownList = props.addTransitItemDropdownList;
  const productMasterDataRaw = props.productMasterDataRaw;
  const refetchData = props.refetchData;
  const overallDataToPrint = useRef();

  const addTransitItemDropdownListFresh = Object.keys(
    productMasterDataRawFresh
  ).map((key, index) => {
    const product = productMasterDataRawFresh[key];
    const item_id = product.item_id;
    const item_description = product.item_description1 || "";
    const brand = product.brand || "";
    const item = {
      value: item_id,
      label: `${item_id}-${brand}-${item_description}`,
    };
    return item;
  });

  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
  });

  const init = () => {
    setIsLoading(true);

    let url = `${service_url}/api/planned-transit-return-items?transit_id=${transitID}&warehouse=${warehouse}&return_date=${moment(
      returnDate
    ).format("YYYY-MM-DD")}&token=${token}`;

    fetch(url, {
      headers: { "x-access-token": sessionStorage.getItem("x-access-token") },
    })
      .then((res) => res.json())
      .then((result) => {
        let itemsData = result.data || [];
        let formattedItemsData = [];
        let bagData = result.bag_data || [];
        const formattedBagData = [];
        let highValueData = itemsData.filter(item => item.variety == 'HIGH VALUE')
        const formattedItemsDataHighValue = [];
        setIsLoading(false);

        let _isApprovedFieldsShown = false;
        let _isApprovedFieldsEditable = true;
        let _isApprovedFieldsShownHighValue = false;
        let _isApprovedFieldsEditableHighValue = true;

        if (itemsData.length > 0) {
        for (let i = 0; i < itemsData.length; i++) {
          itemsData[i].rec_remarks = itemsData[i].received_remarks;
          itemsData[i].app_remarks = itemsData[i].approved_remarks;

          if (
            Number(itemsData[i].inventory) === 0 &&
            Number(itemsData[i].return_qty) === 0
          ) {
            itemsData[i].isNewItem = true;
          } else {
            itemsData[i].isNewItem = false;
          }
          if (
            itemsData[i].return_qty &&
            itemsData[i].received_qty &&
            itemsData[i].return_qty !== itemsData[i].received_qty
          ) {
            itemsData[i].showApprovedQtyField = true;
          } else if (
            itemsData[i].isNewItem &&
            itemsData[i].return_qty !== itemsData[i].received_qty
          ) {
            itemsData[i].showApprovedQtyField = true;
          } else {
            itemsData[i].showApprovedQtyField = false;
          }

          if (itemsData[i].received_qty && itemsData[i].received_qty > 0) {
            _isApprovedFieldsShown = true;
          }
          if (itemsData[i].approved_qty && itemsData[i].approved_qty > 0) {
            _isApprovedFieldsEditable = false;
          }

          formattedItemsData.push(itemsData[i]);
        }
      }
      if (highValueData.length > 0) {
        for (let i = 0; i < highValueData.length; i++) {
          highValueData[i].rec_remarks = highValueData[i].received_remarks;
          highValueData[i].app_remarks = highValueData[i].approved_remarks;

          if (
            Number(highValueData[i].inventory) === 0 &&
            Number(highValueData[i].return_qty) === 0
          ) {
            highValueData[i].isNewItem = true;
          } else {
            highValueData[i].isNewItem = false;
          }
          if (
            highValueData[i].return_qty &&
            highValueData[i].received_qty &&
            highValueData[i].return_qty !== highValueData[i].received_qty
          ) {
            highValueData[i].showApprovedQtyField = true;
          } else if (
            highValueData[i].isNewItem &&
            highValueData[i].return_qty !== highValueData[i].received_qty
          ) {
            highValueData[i].showApprovedQtyField = true;
          } else {
            highValueData[i].showApprovedQtyField = false;
          }

          if (highValueData[i].received_qty && highValueData[i].received_qty > 0) {
            _isApprovedFieldsShownHighValue = true;
          }
          if (highValueData[i].approved_qty && highValueData[i].approved_qty > 0) {
            _isApprovedFieldsEditableHighValue = false;
          }

          formattedItemsDataHighValue.push(highValueData[i]);
        }
      }
      if (bagData.length > 0) {
        for (let i = 0; i < bagData.length; i++) {
          // if (!Number(bagData[i].inventory)) {
          //If inventory is zero no need to show this record in the modal
          // continue;
          // }

          bagData[i].app_remarks = bagData[i].approved_remarks;
          bagData[i].rec_remarks = bagData[i].received_remarks;
          if (
            bagData[i].return_qty &&
            bagData[i].received_qty &&
            bagData[i].return_qty !== bagData[i].received_qty
          ) {
            bagData[i].showApprovedQtyField = true;
          } else {
            bagData[i].showApprovedQtyField = false;
          }

          if (bagData[i].received_qty && bagData[i].received_qty > 0) {
            _isApprovedFieldsShown = true;
          }
          if (bagData[i].approved_qty && bagData[i].approved_qty > 0) {
            _isApprovedFieldsEditable = false;
          }

          formattedBagData.push(bagData[i]);
        }
      }
      if (formattedItemsData.length > 0) {
        formattedItemsData = formattedItemsData.filter(item => item.variety !== 'HIGH VALUE')

        itemsData = sortByTypeCategoryItemId(formattedItemsData);
      }else{
        itemsData = [];
      }
      if (formattedItemsDataHighValue.length > 0) {
        highValueData = sortByTypeCategoryItemId(formattedItemsDataHighValue);
      }else{
        highValueData = [];
      }
      if (formattedBagData.length > 0) {
        bagData = sortByTypeCategoryItemId(formattedBagData);
      } else {
        bagData = [];
      }

        setIsApprovedQtyFieldsShown(_isApprovedFieldsShown);
        // if (!_isApprovedFieldsEditable && userType === "Admin") {
        //   _isApprovedFieldsEditable = true;
        // }
        setIsApprovedQtyFieldsEditable(_isApprovedFieldsEditable);
        setIsApprovedQtyFieldsShownHighValue(_isApprovedFieldsShownHighValue)
        setIsApprovedQtyFieldsEditableHighValue(_isApprovedFieldsEditableHighValue)
        setOverallReturnSummaryDetails(itemsData);
        setOverallHighValueReturnSummaryDetails(highValueData);
        setOverallLooseSummaryDetails(bagData);
      })
      .catch((err) => {
        setIsLoading(false);
        toast(`Error in fetching items: ${err}`, { type: toast.TYPE.ERROR });
      });
  };

  useEffect(() => {
    init();
  }, []);

  const autoFillTableData = () => {
    setIsLoading(true);
    const _overallReturnSummaryDetails = [...overallReturnSummaryDetails];
    overallReturnSummaryDetails.forEach((item) => {
      if (item.return_qty) {
        item.received_qty = item.received_qty ?? item.return_qty;
      }
    });
    const _overallLooseSummaryDetails = [...overallLooseSummaryDetails];
    overallLooseSummaryDetails.forEach((item) => {
      item.received_qty = item.received_qty ?? item.return_qty;
    });
    const _overallHighSummaryDetails = [...overallHighValueReturnSummaryDetails];
    overallHighValueReturnSummaryDetails.forEach((item) => {
      item.received_qty = item.received_qty ?? item.return_qty;
    });

    setIsLoading(false);
    setOverallReturnSummaryDetails(_overallReturnSummaryDetails);
    setOverallLooseSummaryDetails(_overallLooseSummaryDetails);
    setOverallHighValueReturnSummaryDetails(_overallHighSummaryDetails);
    toast(`Auto fill successful !`, { type: toast.TYPE.SUCCESS });
  };

  const itemAlreadyInList = (item_id) => {
    let existingItems = overallReturnSummaryDetails;
    let itemAlreadyPresent = false;
    existingItems.forEach((item, index) => {
      if (Number(item_id) === Number(item.item_id)) {
        itemAlreadyPresent = true;
      }
    });

    existingItems = overallLooseSummaryDetails;
    existingItems.forEach((item, index) => {
      if (Number(item_id) === Number(item.item_id)) {
        itemAlreadyPresent = true;
      }
    });

    return itemAlreadyPresent;
  };

  const addItemToSummaryDetails = (item_id) => {
    if (!item_id) {
      return console.log(" Error:  Missing arguements ");
    }

    if (itemAlreadyInList(item_id)) {
      toast(`This item is already present in the list`, {
        type: toast.TYPE.WARNING,
      });
      return;
    }
    const item = productMasterDataRaw[item_id];

    if (!item) {
      return console.log("Error : Unable to retrieve product info");
    }
    const category = item.category || "";
    const brand = item.brand || "";
    const variety = item.variety || "";
    const sub_category = item.sub_category;
    const item_disc = item.long_description || "";
    const return_date = moment(returnDate).format("YYYY-MM-DD");

    const itemToAdd = {
      transit_id: transitID,
      item_id,
      category,
      variety,
      return_date,
      brand,
      sub_category,
      warehouse,
      item_disc,
      bgColor: greenHighlightColor,
      isNewItem: true,
    };

    toast(`Successfully added new item`, {
      type: toast.TYPE.SUCCESS,
    });
    const existingRouteSummaryDetails = [...overallReturnSummaryDetails];
    existingRouteSummaryDetails.push(itemToAdd);

    setOverallReturnSummaryDetails(existingRouteSummaryDetails);
    setSelectedItemId({});
  };

  const removeItemFromSummaryDetails = (item_id, transit_id) => {
    return new Promise((resolve, reject) => {
      let url = `${service_url}/api/return-transit-route-item?token=${token}`;
      const data = { item_id, transit_id };
      // console.log("check data", data);

      fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": sessionStorage.getItem("x-access-token"),
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          toast(`Successfully removed item`, {
            type: toast.TYPE.SUCCESS,
          });

          resolve();
        })
        .catch((err) => {
          toast(`Unable to remove item`, {
            type: toast.TYPE.ERROR,
          });
          reject(err);
        });
    });
  };

  const validateRemarksFields = (
    returnItem,
    isApprovedQtyFieldsShown,
    isApprovedQtyFieldsEditable
  ) => {
    let return_qty = returnItem.return_qty;
    const remarks = returnItem.remarks;
    let inventory = returnItem.inventory;

    if (!isApprovedQtyFieldsShown) {
      if (return_qty != inventory && !remarks) {
        toast(`Please enter remarks for all the fields`, {
          type: toast.TYPE.ERROR,
        });
        return false;
      }
    } else if (isApprovedQtyFieldsShown && isApprovedQtyFieldsEditable) {
      if (returnItem.showApprovedQtyField && !remarks) {
        toast(`Please enter remarks for all the fields`, {
          type: toast.TYPE.ERROR,
        });
        return false;
      }
    }
    return true;
  };

  const updateApprovedItemsQty = () => {
    let itemsList = [];
    let newItemsList = [];
    for (let returnItem of overallReturnSummaryDetails) {
      let dataToPush = {};
      let item_id = returnItem.item_id;
      let inventory = returnItem.inventory;
      let return_qty = returnItem.return_qty
        ? Number(returnItem.return_qty)
        : 0;
      let received_qty = returnItem.received_qty;
      const received_remarks = returnItem.rec_remarks;
      const approved_remarks = returnItem.app_remarks;
      const item_disc = returnItem.item_disc;
      const category = returnItem.category;
      const variety = returnItem.variety;

      if (!received_qty) {
        return toast(`Please fill the quantity for all rows`, {
          type: toast.TYPE.WARNING,
        });
      } else if (received_qty < 0) {
        return toast(`Received qty cannot be negative`, {
          type: toast.TYPE.ERROR,
        });
      }

      // if (!received_remarks) {
      //   return toast(`Please enter remarks for all the fields`, {
      //     type: toast.TYPE.ERROR,
      //   });
      // }

      dataToPush = {
        item_id,
        inventory,
        return_qty,
        received_qty,
        received_remarks,
        item_disc,
        category,
        variety,
      };

      if (isApprovedQtyFieldsEditable && isApprovedQtyFieldsShown) {
        if (returnItem.showApprovedQtyField && !returnItem.approved_qty) {
          return toast(`No changes to submit`, {
            type: toast.TYPE.WARNING,
          });
        } else if (
          (returnItem.approved_qty || returnItem.approved_qty === 0) &&
          returnItem.approved_qty <= 0
        ) {
          return toast(`Approved quantity cannot be negative or zero`, {
            type: toast.TYPE.ERROR,
          });
        } else if (returnItem.showApprovedQtyField && !approved_remarks) {
          return toast(`Please enter remarks for all the fields`, {
            type: toast.TYPE.ERROR,
          });
        } else if (returnItem.approved_qty) {
          dataToPush["approved_remarks"] = approved_remarks;
          dataToPush["approved_qty"] = returnItem.approved_qty;
          itemsList.push(dataToPush);
        }
      } else if (!returnItem.isNewItem) {
        itemsList.push(dataToPush);
      } else {
        newItemsList.push(dataToPush);
      }
    }
    for (let returnItem of overallLooseSummaryDetails) {
      let dataToPush = {};
      let item_id = returnItem.item_id;
      let inventory = returnItem.inventory;
      let return_qty = returnItem.return_qty
        ? Number(returnItem.return_qty)
        : 0;
      let received_qty = returnItem.received_qty;
      let bag_id = returnItem.bag_id;
      const received_remarks = returnItem.rec_remarks;
      const approved_remarks = returnItem.app_remarks;

      if (!received_qty) {
        return toast(`Please fill the quantity for all rows`, {
          type: toast.TYPE.WARNING,
        });
      } else if (received_qty < 0) {
        return toast(`Received quantity cannot be negative`, {
          type: toast.TYPE.WARNING,
        });
      }

      // if (!received_remarks) {
      //   return toast(`Please enter remarks for all the fields`, {
      //     type: toast.TYPE.WARNING,
      //   });
      // }
      dataToPush = {
        item_id,
        inventory,
        return_qty,
        received_qty,
        bag_id,
        received_remarks,
      };

      // if (
      //   !validateRemarksFields(
      //     returnItem,
      //     isApprovedQtyFieldsShown,
      //     isApprovedQtyFieldsEditable
      //   )
      // )
      //   return;
      // else dataToPush.remarks = returnItem.remarks;

      if (isApprovedQtyFieldsEditable && isApprovedQtyFieldsShown) {
        if (returnItem.showApprovedQtyField && !returnItem.approved_qty) {
          return toast(`No changes to submit`, {
            type: toast.TYPE.WARNING,
          });
          // return toast(`Please fill the quantity for all rows`, {
          //   type: toast.TYPE.WARNING,
          // });
        } else if (
          returnItem.showApprovedQtyField &&
          returnItem.approved_qty < 0
        ) {
          return toast(`Approved quantity cannot be negative`, {
            type: toast.TYPE.ERROR,
          });
        } else if (returnItem.showApprovedQtyField && !approved_remarks) {
          return toast(`Please enter remarks for all the fields`, {
            type: toast.TYPE.ERROR,
          });
        } else if (returnItem.showApprovedQtyField) {
          dataToPush["approved_qty"] = returnItem.approved_qty;
          dataToPush["approved_remarks"] = approved_remarks;
          itemsList.push(dataToPush);
        }
      } else {
        itemsList.push(dataToPush);
      }
    }
    for (let returnItem of overallHighValueReturnSummaryDetails) {
      let dataToPush = {};
      let item_id = returnItem.item_id;
      let inventory = returnItem.inventory;
      let return_qty = returnItem.return_qty
        ? Number(returnItem.return_qty)
        : 0;
      let received_qty = returnItem.received_qty;
      const received_remarks = returnItem.rec_remarks;
      const approved_remarks = returnItem.app_remarks;
      const item_disc = returnItem.item_disc;
      const category = returnItem.category;
      const variety = returnItem.variety;
      const crate_id = returnItem.crate_id;

      if (!received_qty) {
        return toast(`Please fill the quantity for all rows`, {
          type: toast.TYPE.WARNING,
        });
      } else if (received_qty < 0) {
        return toast(`Received qty cannot be negative`, {
          type: toast.TYPE.ERROR,
        });
      }

     

      dataToPush = {
        item_id,
        inventory,
        return_qty,
        received_qty,
        received_remarks,
        item_disc,
        category,
        variety,
        crate_id
      };

      if (isApprovedQtyFieldsEditableHighValue && isApprovedQtyFieldsShownHighValue) {
        if (returnItem.showApprovedQtyField && !returnItem.approved_qty) {
          return toast(`No changes to submit`, {
            type: toast.TYPE.WARNING,
          });
        } else if (
          (returnItem.approved_qty || returnItem.approved_qty === 0) &&
          returnItem.approved_qty <= 0
        ) {
          return toast(`Approved quantity cannot be negative or zero`, {
            type: toast.TYPE.ERROR,
          });
        } else if (returnItem.showApprovedQtyField && !approved_remarks) {
          return toast(`Please enter remarks for all the fields`, {
            type: toast.TYPE.ERROR,
          });
        } else if (returnItem.approved_qty) {
          dataToPush["approved_remarks"] = approved_remarks;
          dataToPush["approved_qty"] = returnItem.approved_qty;
          itemsList.push(dataToPush);
        }
      } else if (!returnItem.isNewItem) {
        itemsList.push(dataToPush);
      } else {
        newItemsList.push(dataToPush);
      }
    }


    let url = `${service_url}/api/return-approved-item-qty?token=${token}`;

    let data = {
      updatedItems: itemsList,
      new_items: newItemsList,
      transit_id: transitID,
      return_date: moment(returnDate).format("YYYY-MM-DD"),
      created_by: JSON.parse(sessionStorage.getItem(`user`))[0].email,
      warehouse,
    };
    if (isApprovedQtyFieldsEditable && isApprovedQtyFieldsShown) {
      data["approved_by"] = JSON.parse(sessionStorage.getItem(`user`))[0].email;
      data["approved_at"] = new Date().toJSON().slice(0, 19).replace("T", " ");
    } else {
      data["received_by"] = JSON.parse(sessionStorage.getItem(`user`))[0].email;
      data["received_at"] = new Date().toJSON().slice(0, 19).replace("T", " ");
      data["approved_at"] = moment().format("YYYY-MM-DD HH:mm:ss");
    }

    console.log(data);
    setIsLoading(true);
    fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": sessionStorage.getItem("x-access-token"),
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((result) => {
        setIsLoading(false);
        if (result.success == 1) {
          toast(`${result.message}`, { type: toast.TYPE.SUCCESS });
          refetchData();
          props.onHide();
        } else {
          toast(`${result.message}`, { type: toast.TYPE.ERROR });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast(`Error in updating return transit records: ${err}`, {
          type: toast.TYPE.ERROR,
        });
      });
  };

  const excelHeaders = React.useMemo(
    () => [
      {
        label: "Item ID",
        value: "item_id",
      },
      {
        label: "Item description",
        value: "item_disc",
      },
      {
        label: "Type",
        value: "variety",
      },
      {
        label: "Category",
        value: "category",
      },
      {
        label: "Inventory",
        value: "inventory",
      },

      {
        label: "Return Qty",
        value: "return_qty",
      },
      {
        label: "Received Qty",
        value: "received_qty",
      },
      {
        label: "Approved Qty",
        value: "approved_qty",
      },
    ],
    []
  );

  const excelHeadersLooseItems = React.useMemo(
    () => [
      {
        label: "Order No",
        value: "order_number",
      },
      {
        label: "Bag Id",
        value: "bag_id",
      },
      {
        label: "Item Id",
        value: "item_id",
      },
      {
        label: "Item description",
        value: "item_disc",
      },
      {
        label: "Category",
        value: "category",
      },
      {
        label: "Inventory",
        value: "inventory",
      },

      {
        label: "Return Qty",
        value: "return_qty",
      },
      {
        label: "Received Qty",
        value: "received_qty",
      },
      {
        label: "Approved Qty",
        value: "approved_qty",
      },
    ],
    []
  );

  const excelHeadersHighValue = React.useMemo(
    () => [
      {
        label: "Item ID",
        value: "item_id",
      },
      {
        label: "Item description",
        value: "item_disc",
      },
      {
        label: "Type",
        value: "variety",
      },
      {
        label: "Category",
        value: "category",
      },
      {
        label: "Inventory",
        value: "inventory",
      },

      {
        label: "Return Qty",
        value: "return_qty",
      },
      {
        label: "Received Qty",
        value: "received_qty",
      },
      {
        label: "Approved Qty",
        value: "approved_qty",
      },
      {
        label: "Crate Id",
        value: "crate_id",
      },
    ],
    []
  );


  return (
    <div>
      <Modal
        {...props}
        id="transitReturnModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update transit return
          </Modal.Title>

          {!isApprovedQtyFieldsShown && (
            <MantineButton
              style={{ marginLeft: "1rem", alignSelf: "flex-end" }}
              compact
              variant="gradient"
              gradient={{ from: "orange", to: "red" }}
              color="yellow"
              onClick={() => {
                autoFillTableData();
              }}
            >
              Auto fill received qty
            </MantineButton>
          )}
        </Modal.Header>

        <Modal.Body>
          {overallReturnSummaryDetails?.length > 0 ||
          overallLooseSummaryDetails?.length > 0 || 
          overallHighValueReturnSummaryDetails?.length > 0 ? (
            <>
              <div>
                <div
                  className="clearFix"
                  style={{
                    float: "right",
                    marginBottom: "20px",
                    marginLeft: "30px",
                  }}
                >
                  <ExportToExcel
                    sheet1={overallReturnSummaryDetails}
                    sheet2={overallLooseSummaryDetails}
                    sheet3={overallHighValueReturnSummaryDetails}
                    filename={"transit_stock_return_summary"}
                    sheet1Headers={excelHeaders}
                    sheet2Headers={excelHeadersLooseItems}
                    sheet3Headers={excelHeadersHighValue}
                    sheet1Name={warehouse}
                    sheet2Name="Loose Items"
                    sheet3Name="High Value"
                  />
                </div>
                <div
                  className="clearFix"
                  style={{ float: "right", marginBottom: "20px" }}
                >
                  <ShowDiscrepancyCheckbox
                    showDiscrepancyItemsChecked={showDiscrepancyItemsChecked}
                    setShowDiscrepancyItemsChecked={
                      setShowDiscrepancyItemsChecked
                    }
                  />
                </div>

                <OverallTransitReturnSummaryTable
                  overallReturnSummaryDetails={overallReturnSummaryDetails}
                  overallHighValueReturnSummaryDetails = {overallHighValueReturnSummaryDetails}
                  overallLooseSummaryDetails={overallLooseSummaryDetails}
                  setOverallReturnSummaryDetails={
                    setOverallReturnSummaryDetails
                  }
                  setOverallHighValueReturnSummaryDetails = {setOverallHighValueReturnSummaryDetails}
                  setOverallLooseSummaryDetails={setOverallLooseSummaryDetails}
                  currentSelectedRoute={currentSelectedRoute}
                  removeItemFromSummaryDetails={removeItemFromSummaryDetails}
                  transitID={transitID}
                  init={init}
                  isApprovedQtyFieldsShown={isApprovedQtyFieldsShown}
                  isApprovedQtyFieldsShownHighValue = {isApprovedQtyFieldsShownHighValue}
                  warehouse={warehouse}
                  showDiscrepancyItemsChecked={showDiscrepancyItemsChecked}
                  isApprovedQtyFieldsEditable={isApprovedQtyFieldsEditable}
                  isApprovedQtyFieldsEditableHighValue = {isApprovedQtyFieldsEditableHighValue}
                />
              </div>
            </>
          ) : (
            ""
          )}

          {(userType === "Admin" || userCity === GLOBAL_PARENT_WAREHOUSE) &&
          !isApprovedQtyFieldsShown ? (
            <>
              <Row style={{ width: "100%" }}>
                <Col style={{ width: "150%" }}>
                  <Select
                    options={
                      type === "GT"
                        ? addTransitItemDropdownList
                        : addTransitItemDropdownListFresh
                    }
                    value={selectedItemId}
                    onChange={(val) => {
                      // console.log("selected value", val);
                      setSelectedItemId(val);
                    }}
                    isClearable={true}
                  />
                </Col>

                <Col style={{ width: "10%" }}>
                  <Button
                    onClick={() => {
                      if (selectedItemId && selectedItemId.value) {
                        addItemToSummaryDetails(selectedItemId.value);
                      } else {
                        toast(`Please select a valid item id from the list`, {
                          type: toast.TYPE.WARNING,
                        });
                      }
                    }}
                  >
                    Add item
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer>
          {!isUpdateBtnDisabled(
            userType,
            userCity,
            isApprovedQtyFieldsShown,
            isApprovedQtyFieldsEditable
          ) && formatDate(new Date()) == formatDate(returnDate) ? (
            <Button
              disabled={isLoading}
              onClick={() => {
                updateApprovedItemsQty();
              }}
            >
              Update
            </Button>
          ) : null}

          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>

      {isLoading ? <Backdrop parentLoadStatus={isLoading} /> : null}
    </div>
  );
}

const isUpdateBtnDisabled = (
  user_type,
  user_city,
  is_approved_qty_fields_shown,
  is_approved_qty_fields_editable
) => {
  if (
    user_type.toLowerCase() === "admin" ||
    user_type.toLowerCase() === "systems"
  ) {
    return false;
  } else if (is_approved_qty_fields_shown && !is_approved_qty_fields_editable) {
    return true;
  } else if (!is_approved_qty_fields_shown) {
    //Received Qty Field
    if (user_city === GLOBAL_PARENT_WAREHOUSE) {
      return false;
    } else if (user_type === "Operation" && user_city === "") {
      return false;
    } else {
      return true;
    }
  } else if (is_approved_qty_fields_shown && is_approved_qty_fields_editable) {
    // Approved Qty Field
    if (
      user_city === GLOBAL_PARENT_WAREHOUSE ||
      (user_type === "Operation" && user_city === "")
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

const OverallTransitReturnSummaryTable = (props) => {
  let routeDataToShow = props.overallReturnSummaryDetails;
  const overallHighReturnSummaryDetails = props.overallHighValueReturnSummaryDetails;
  const overallLooseSummaryDetails = props.overallLooseSummaryDetails;
  const setOverallLooseSummaryDetails = props.setOverallLooseSummaryDetails;
  const setOverallReturnSummaryDetails = props.setOverallReturnSummaryDetails;
  const setOverallHighReturnSummaryDetails = props.setOverallHighValueReturnSummaryDetails;
  const currentSelectedRoute = props.currentSelectedRoute;
  const removeItemFromSummaryDetails = props.removeItemFromSummaryDetails;
  const transitID = props.transitID;
  const init = props.init;
  const warehouse = props.warehouse;
  const isApprovedQtyFieldsShown = props.isApprovedQtyFieldsShown;
  const isApprovedQtyFieldsEditable = props.isApprovedQtyFieldsEditable;
  const isApprovedQtyFieldsShownHighValue = props.isApprovedQtyFieldsShownHighValue;
  const isApprovedQtyFieldsEditableHighValue = props.isApprovedQtyFieldsEditableHighValue;
  const showDiscrepancyItemsChecked = props.showDiscrepancyItemsChecked;

  return (
    <div style={{ pageBreakBefore: "always" }}>
       {routeDataToShow?.length > 0 && 
      <TransitReturnTable
        routeProductData={routeDataToShow}
        currentSelectedRoute={currentSelectedRoute}
        overallReturnSummaryDetails={props.overallReturnSummaryDetails}
        removeItemFromSummaryDetails={removeItemFromSummaryDetails}
        setOverallReturnSummaryDetails={setOverallReturnSummaryDetails}
        transitID={transitID}
        init={init}
        isApprovedQtyFieldsShown={isApprovedQtyFieldsShown}
        warehouse={warehouse}
        isApprovedQtyFieldsEditable={isApprovedQtyFieldsEditable}
        showDiscrepancyItemsChecked={showDiscrepancyItemsChecked}
      />}
       {overallLooseSummaryDetails?.length > 0 && <>
      <center>
        <h4>Loose Items</h4>
      </center>
      <TransitReturnLooseTable
        routeProductData={overallLooseSummaryDetails}
        currentSelectedRoute={currentSelectedRoute}
        overallReturnSummaryDetails={overallLooseSummaryDetails}
        removeItemFromSummaryDetails={removeItemFromSummaryDetails}
        setOverallReturnSummaryDetails={setOverallLooseSummaryDetails}
        transitID={transitID}
        init={init}
        isApprovedQtyFieldsShown={isApprovedQtyFieldsShown}
        warehouse={warehouse}
        isApprovedQtyFieldsEditable={isApprovedQtyFieldsEditable}
        showDiscrepancyItemsChecked={showDiscrepancyItemsChecked}
      />
      </>}
      {overallHighReturnSummaryDetails?.length > 0 && <>
    
       <center>
        <h4>High Value Items</h4>
      </center>
      <TransitReturnHighValueTable
        routeProductData={overallHighReturnSummaryDetails}
        currentSelectedRoute={currentSelectedRoute}
        overallReturnSummaryDetails={overallHighReturnSummaryDetails}
        // removeItemFromSummaryDetails={removeItemFromSummaryDetails}
        setOverallReturnSummaryDetails={setOverallHighReturnSummaryDetails}
        transitID={transitID}
        init={init}
        isApprovedQtyFieldsShown={isApprovedQtyFieldsShownHighValue}
        warehouse={warehouse}
        isApprovedQtyFieldsEditable={isApprovedQtyFieldsEditableHighValue}
        showDiscrepancyItemsChecked={showDiscrepancyItemsChecked}
      />  </>}

    </div>
  );
};

export default TransitReturnItemsModal;
